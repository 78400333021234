<template>
  <div class="confim" :class="{ active: show }">
    <div class="confimBox">
      <div class="title">
        {{ title }}
        <span
          v-show="showType == 3"
          @click="closeConfim"
          class="closeIcon"
        ></span>
      </div>
      <div class="content">
        <div class="success" v-if="showType == 1">
          <img src="@/assets/icon/icon@2x.png" alt="" />
        </div>
        <div class="conText" v-else-if="showType == 2">
          <span v-html="descriptText" style="white-space: pre-wrap"></span>
        </div>
        <div class="edit" v-else-if="showType == 3">
          <input
            v-model.trim="name"
            type="text"
            class="editBox"
            :placeholder="
              $store.state.language == 1 ? '輸入用戶名' : 'Username'
            "
          />
        </div>
      </div>
      <div class="btn" @click="closeConfim">{{ btnText }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Confim",
  data() {
    return {
      name: "",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    showType: {
      type: Number,
      default: 1,
    },
    descriptText: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeConfim() {
      if (this.showType == 3) {
        if (this.name) {
          this.$emit("closeCon", { name: this.name });
        }
      }
      this.$emit("closeCon");
    },
  },
};
</script>
<style scoped>
.confim {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.2s linear;
  z-index: 1;
}
.confim.active {
  opacity: 1;
}
.confimBox {
  width: 3rem;
  background-color: #fff;
  border-radius: 0.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.24rem 0.16rem 0.16rem;
  box-sizing: border-box;
}
.title {
  height: 0.24rem;
  font-size: 0.17rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.24rem;
  color: #03060d;
  position: relative;
}
.btn {
  width: 100%;
  height: 0.44rem;
  margin: 0 auto;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.44rem;
  color: #ffffff;
  background-color: #e4022b;
  border-radius: 0.1rem;
}
/* 成功提示 */
.success {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0.24rem auto 0.28rem;
  position: relative;
}
.success img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
}
.conText {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.18rem;
  color: #474747;
  margin: 0.16rem 0 0.21rem;
  min-height: 1rem;
  max-height: 3rem;
  overflow: scroll;
  text-align: left;
  word-break: break-all;
  scrollbar-width: none !important;
  scrollbar-color: #fff !important;
}
.conText::-webkit-scrollbar {
  display: none;
}
.closeIcon {
  width: 0.16rem;
  height: 0.16rem;
  overflow: hidden;
  background: url("../../assets/icon/close.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0.04rem;
  left: 0.04rem;
}
.edit {
  height: 0.44rem;
  position: relative;
  margin: 0.24rem 0 0.16rem;
}
.editBox {
  width: 100%;
  height: 0.44rem;
  background: #f2f3f5;
  border-radius: 0.04rem;
  border: none;
  outline: none;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
  padding: 0 0.12rem;
  box-sizing: border-box;
}
</style>