import store from "../../store";

export default function (book, second) {
    let nowTime = new Date().getTime(); //当前时间

    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    var day = new Date().getDate();
    var date = year + "/" + month + "/" + day + " ";
    if (book == 2) {
        nowTime = new Date(second).getTime(); //当前时间
        date = second.split(" ")[0]
    }
    var breakfast = new Date(date + " 10:59").getTime();
    var lunch = new Date(date + " 14:29").getTime();
    var tea = new Date(date + " 17:29").getTime();
    var saveTimeType;
    if (nowTime <= breakfast) {
        saveTimeType = "H01";
    } else if (nowTime <= lunch) {
        saveTimeType = "H02";
    } else if (nowTime <= tea) {
        saveTimeType = "H03";
    } else {
        saveTimeType = "H04";
    }
    store.commit("saveTime", saveTimeType);
}