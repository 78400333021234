<template>
  <div class="couponItem">
    <div class="coupon">
      <img :src="couponInfo.imageUrl" alt="" />
      <div class="boxMinddle" :class="{ active: !hide }">
        <div class="icon-box">
          <div v-if="couponInfo.couTag.indexOf(1) > -1">
            <p class="typeIcon activeThree" :class="{ scaleWidth: canScale }">
              <i></i>
              <span :class="{ scaleSize: canScale, scaleWidthSpan: canScale }">{{
                lanType == 1 ? "自取" : "Self Pick-up"
              }}</span>
            </p>
          </div>
          <div v-if="couponInfo.couTag.indexOf(2) > -1">
            <p class="typeIcon activeOne">
              <i></i>
              <span :class="{ scaleSize: canScale }">{{
                lanType == 1 ? "外送速遞" : "Delivery"
              }}</span>
            </p>
          </div>
          <div v-if="couponInfo.couTag.indexOf(3) > -1">
            <p class="typeIcon activeTwo">
              <i></i>
              <span :class="{ scaleSize: canScale }">
                {{ lanType == 1 ? "餐廳兌換" : "In Store" }}
              </span>
            </p>
          </div>
          <div v-if="couponInfo.couTag.indexOf(5) > -1">
            <p class="typeIcon activeFour">
              <i></i>
              <span :class="{ scaleSize: canScale }">
                {{ lanType == 1 ? "App兌換" : "By App" }}
              </span>
            </p>
          </div>
        </div>
        <p class="name">{{ couponInfo.voucherName }}</p>
        <p class="btn">
          <span
            >{{ lanType == 1 ? "兌換期" : "Expiry Date " }} :{{
              couponInfo.date
            }}</span
          >
        </p>
      </div>
    </div>
    <div class="descript">
      <div class="descriptTitle">
        <p class="descriptLeft" @click="showDescript">
          <i></i>
          <span>{{ lanType == 1 ? "優惠詳情" : "Offer Details" }}</span>
        </p>
        <div class="btnAll">
          <div
            class="btn-box"
            v-if="showBtn && type != 3 && couponInfo.sendFunc != 1"
          >
            <p
              class="descriptRight"
              @click="showQecode"
              v-if="
                !couponInfo.voucherCategoryCode ||
                couponInfo.voucherCategoryCode.indexOf('A03') > -1
              "
            >
              <span v-if="hide">{{
                lanType == 1 ? "餐廳落單" : "In-store"
              }}</span>
            </p>
            <p
              class="descriptRight"
              @click="selectCoupon"
              v-if="
                !couponInfo.voucherCategoryCode ||
                couponInfo.voucherCategoryCode.indexOf('A05') > -1
              "
            >
              <span v-if="hide">{{ lanType == 1 ? "App落單" : "App" }}</span>
            </p>
          </div>
          <div class="btn-box" v-else>
            <p class="descriptRight" @click="selectCoupon">
              <span v-if="hide">{{
                lanType == 1 ? "使用優惠" : "Use Now"
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="qrcodeBox" v-show="showQrcode">
        <div class="qrcode" ref="code">
          <!-- <img src="../../assets/icon/erweima.png" alt="" /> -->
        </div>
        <p class="couponWarn">
          {{
            lanType == 1
              ? "向餐廳職員出示二維碼"
              : "Present QR code at the Cashier"
          }}
        </p>
      </div>
    </div>
    <confim
      v-show="showCon"
      @closeCon="closeConfim"
      :show="showCon"
      :title="lanType == 1 ? '優惠詳情' : 'Offer Details'"
      :showType="2"
      :btnText="lanType == 1 ? '確認' : 'Confirm'"
      :descriptText="couponInfo.termCondition || ''"
    ></confim>
  </div>
</template>
<script>
import Confim from "../../components/common/Confim.vue";
import AraleQRCode from "arale-qrcode";
import { mapState } from "vuex";

export default {
  name: "CouponList",
  data() {
    return {
      showQrcode: false,
      showCon: false,
      showSelect: false,
      lanType: this.$store.state.language,
    };
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
    hide: {
      type: Boolean,
      default: true,
    },
    couponInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    page: {
      type: Number,
      default: 0,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showDescript() {
      this.showCon = true;
    },
    closeConfim() {
      this.showCon = false;
    },
    createQRcode() {
      var code = new AraleQRCode({
        render: "png", // 生成的类型 'svg' or 'table'
        text: this.couponInfo.voucherCode || "", // 需要生成二维码的链接
        size: 128, // 生成二维码大小
      });
      this.$refs.code.appendChild(code);
    },
    // 餐廳使用
    showQecode() {
      this.showQrcode = !this.showQrcode;
    },
    setQRcode() {
      this.showQrcode = false;
    },
    // 立即使用
    selectCoupon() {
      this.$store.commit("saveTimeSelectCoupon");
      this.$emit("couponDataLayPush", this.couponInfo);
      if (this.page == 1) {
        this.$emit("selectCoupon");
        return;
      }
      if (this.couponInfo.voucherStatus != 1) {
        var A =
          this.$store.state.language == 1
            ? "優惠已完結"
            : "This promotion has ended";
        return this.$toast(A);
      }
      var type = this.$store.state.time;
      if (
        !this.couponInfo.voucherHashtagCode ||
        this.couponInfo.voucherHashtagCode.indexOf(type) > -1
      ) {
        if (this.couponInfo.voucherCategoryCode) {
          //通用
          if (this.couponInfo.sendFunc == 0) {
            this.enterPage(1);
            this.$emit("selectCoupon", this.couponInfo);
          } else if (this.couponInfo.sendFunc == 2) {
            // 到店专用
            this.enterPage(2);
          } else if (this.couponInfo.sendFunc == 1) {
            // 外送专用
            this.enterPage(3);
          }
        } else {
          this.enterPage(1);
          this.$emit("selectCoupon", this.couponInfo);
        }
      } else {
        var AL =
          this.$store.state.language == 1
            ? "優惠須於指定時段內使用"
            : "This promotion is valid for specific time";
        this.$toast(AL);
      }
    },
    // 选择优惠券之后进入对应页面
    enterPage(routeType) {
      var coupon = JSON.stringify(this.couponInfo);
      coupon = encodeURIComponent(coupon);
      if (this.type == 2) {
        routeType = 2;
      } else if (this.type == 3) {
        routeType = 3;
      }
      if (routeType == 1) {
        this.$emit("showPick");
      } else if (routeType == 2) {
        this.$router.push({
          path: "/selectShop",
          query: {
            type: 3,
            coupon: coupon,
          },
        });
      } else {
        // 是否是自取才可以使用的券
        let isZQ = false;
        if (
          this.couponInfo.voucherCategoryCode &&
          this.couponInfo.voucherCategoryCode.indexOf("A02") < 0 &&
          this.couponInfo.voucherCategoryCode.indexOf("A01") > -1
        ) {
          isZQ = true;
        }
        // 如果自取和外卖都可以用 默认外卖
        if (this.$store.state.placeInfo && !isZQ) {
          var param = {
            businessType: "1",
            coupon: this.couponInfo,
          };
          param = JSON.stringify(param);
          param = encodeURIComponent(param);
          this.$router.push("/orderFood/" + param);
        } else {
          // 如果是自取和堂食
          this.$router.push({
            path: "/selectShop",
            query: {
              type: isZQ ? 2 : 1,
              coupon: coupon,
            },
          });
        }
      }
    },
  },
  computed: {
    ...mapState(["isPC"]),
    canScale() {
      if (this.isPC && this.$store.state.language != 1) {
        return true;
      }
      return false;
    },
  },
  updated() {
    this.createQRcode();
  },
  components: {
    Confim,
  },
};
</script>
<style scoped>
.couponItem {
  width: 100%;
  border-radius: 0.1rem;
  background-color: #fff;
  margin-bottom: 0.12rem;
  overflow: hidden;
}
.coupon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.coupon div p + p {
  margin-top: 0.08rem;
}
.coupon img {
  width: 1.49rem;
  height: 1.07rem;
  object-fit: cover;
  margin-right: 0.1rem;
}
.typeIcon {
  /* width: 0.8rem; */
  height: 0.18rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: PingFang HK;
  font-style: normal;
  font-weight: 600;
  font-size: 0.1rem;
  line-height: 0.18rem;
  color: #03060d;
  padding: 0.02rem 0;
  box-sizing: border-box;
  border-radius: 0.02rem;
  padding: 0 0.05rem;
  letter-spacing: 0;
}
.icon-box.active .typeIcon span {
  display: inline-block;
  white-space: nowrap;
  transform: scale(0.6);
  overflow: hidden;
  letter-spacing: 0;
  word-break: break-all;
  position: absolute;
}
.icon-box.active .typeIcon {
  width: 0.8rem;
}
.icon-box.active .typeIcon.activeThree {
  width: 0.65rem;
}
.icon-box.active .typeIcon.activeTwo {
  width: 0.85rem;
}
.icon-box.active .typeIcon.activeOne span {
  left: 0.1rem;
}
.icon-box.active .typeIcon.activeOne {
  width: 0.5rem;
}
.typeIcon.activeOne {
  background-color: #f2f3f5;
}
.typeIcon i {
  margin-right: 0.04rem;
}
.typeIcon.activeOne i {
  width: 0.13rem;
  height: 0.1rem;
  background: url("../../assets/icon/sd.png") no-repeat center;
  background-size: cover;
}
.typeIcon.activeTwo {
  /* width: 0.6rem; */
  background-color: #f5e9eb;
}
.typeIcon.activeTwo i {
  width: 0.11rem;
  height: 0.11rem;
  background: url("../../assets/icon/g2.png") no-repeat center;
  background-size: cover;
}
.typeIcon.activeThree {
  background-color: #ff7c9b;
}
.typeIcon.activeThree i {
  width: 0.12rem;
  height: 0.12rem;
  background: url("../../assets/icon/zq.png") no-repeat center;
  background-size: cover;
}

.typeIcon.activeFour {
  background-color: #f5e9eb;
}
.typeIcon.activeFour i {
  width: 0.068rem;
  height: 0.114rem;
  background: url("../../assets/icon/g1.png") no-repeat center;
  background-size: 0.068rem 0.114rem;
}
.name,
.code {
  width: 1.6rem;
  height: 0.2rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.2rem;
  color: #02050c;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.code {
  color: #e3002b;
}
.btnAll {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
  text-align: justify;
  word-break: break-all;
  /* padding: ; */
}
.btn span:nth-child(2) {
  width: 0.72rem;
  height: 0.28rem;
  border-radius: 0.08rem;
  background-color: #e4022b;
  color: #fff;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 500;
  line-height: 0.28rem;
}
.descript {
  padding: 0.06rem 0.12rem;
  box-sizing: border-box;
  background-color: #fafafa;
}
.descriptTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.11rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.16rem;
  color: #808080;
}
.descriptLeft,
.descriptRight {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.descriptLeft i {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../../assets/icon/error.png") no-repeat center;
  background-size: cover;
  margin-right: 0.04rem;
}
.descriptRight i {
  width: 0.12rem;
  height: 0.12rem;
  background: url("../../assets/icon/show.png") no-repeat center;
  background-size: cover;
  margin-left: 0.04rem;
}
.descriptRight span {
  width: 0.72rem;
  height: 0.28rem;
  border-radius: 0.14rem;
  background-color: #e4022b;
  color: #fff;
  font-size: 0.14rem;
  text-align: center;
  line-height: 0.28rem;
}
.qrcode {
  width: 1.28rem;
  height: 1.28rem;
  overflow: hidden;
  margin: 0.24rem auto 0.06rem;
}
.qrcode img {
  width: 100%;
  height: 100%;
}
.couponWarn {
  width: 100%;
  height: 0.16rem;
  margin-bottom: 0.04rem;
  margin-top: 0.07rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 0.16rem;
  text-align: center;
  color: #000000;
}
.boxMinddle.active {
  width: 1.5rem !important;
  overflow: hidden !important;
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn-box p {
  margin-top: 0 !important;
  margin-left: 0.08rem;
}
.icon-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icon-box p {
  margin-top: 0 !important;
  position: relative;
}
.icon-box div + div {
  margin-left: 0.05rem;
}

.scaleSize {
  white-space: nowrap !important;
  transform: scale(0.8) !important;
}
.scaleWidthSpan {
  width: 0.66rem !important;
}
.scaleWidth {
  width: 0.9rem !important;
}
</style>